.root {
  height: 8px;
  width: 26px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.root > span {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: var(--bg, black);
  margin-left: 1px;
  transform-origin: center;
  opacity: 0.3;
  transform: scale(0.8);
}
.root > span:first-child {
  margin-left: 0;
}
.root > span:first-child {
  animation: dot 1.2s linear infinite;
}
.root > span:nth-child(2) {
  animation: dot 1.2s linear infinite 0.15s;
}
.root > span:nth-child(3) {
  animation: dot 1.2s linear infinite 0.3s;
}
@keyframes dot {
  from,
  50%,
  to {
    transform: scale(0.8);
    opacity: 0.3;
  }
  25% {
    transform: scale(1);
    opacity: 1;
  }
}
