.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  border: solid 2px;
  border-radius: 50px;
  line-height: 1;
  overflow: hidden;
  padding: 0 26px;
  height: 40px;
  position: relative;
  transition: box-shadow 0.3s cubic-bezier(0.76, 0, 0.24, 1),
    color 0.3s cubic-bezier(0.76, 0, 0.24, 1)/*,
    transform 0.2s cubic-bezier(0.76, 0, 0.24, 1)*/;
  z-index: 1;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  background-color: var(--backgroundColor);
  color: var(--textColor);
  border-color: var(--borderColor);
  transform-origin: center center;
}
.shadow {
  box-shadow: 0 10px 40px rgb(0 0 0 / 10%);
}
.root:hover {
  color: var(--fillTextColor);
  /*transform: scale(0.95);*/
}
.root:active {
  transform: scale(0.98);
}
.shadow:hover {
  box-shadow: 0 10px 40px transparent;
}
.root::before {
  border-radius: 50%;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  width: 0;
  z-index: -1;
  background-color: var(--fillColor);
}
.root:hover::before {
  opacity: 1;
  padding-top: calc(100% + 4px);
  width: calc(100% + 4px);
}
.root:active {
  /*transform: scale(0.95);*/
}
