.root {
  border-top: 1px solid #e6e8ec;
  display: flex;
  flex-flow: column nowrap;
}
.taglineWrapper {
  padding: 25px 0 20px;
  border-top: 1px solid #e6e8ec;
}
.body {
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px;
}
.column {
  flex: 0 0 30.5%;
  padding: 80px 86px 64px;
}
.column:first-child {
  display: flex;
  flex: 0 0 39%;
  padding-left: 0;
}
.column:not(:first-child) {
  border-left: 1px solid #e6e8ec;
}
.column:last-child {
  padding-right: 0;
}
.logo {
  display: inline-block;
  margin-right: 128px;
  width: 64px;
  overflow: hidden;
  position: relative;
}
.logo > img {
  max-width: 100%;
}
.columnWrapper {
  display: flex;
  flex-flow: column nowrap;
}
.columnHeading {
  margin-bottom: 41px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}
.column:first-child .columnHeading {
  display: none;
}
.columnLinks {
  display: flex;
  flex-flow: column nowrap;
}
.columnLinks > a {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777e90;
  transition: color 0.2s;
}
.columnLinks > a:hover,
.columnLinks > a:active {
  color: #23262f;
}
.columnLinks > a:not(:last-child) {
  margin-bottom: 24px;
}
.columnInfo {
  font-size: 14px;
  line-height: 1.71429;
}
.columnInfo > p:not(:last-child) {
  margin-bottom: 8px;
}
.tagline {
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px;
}
.copyright {
  margin-right: auto;
  font-size: 12px;
  line-height: 1.66667;
  color: #777e90;
}
.socials {
  display: flex;
  flex-flow: row nowrap;
}
.socialLink {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}
.socialLink > * {
  width: 16px;
  height: 16px;
  color: #777e90;
  transition: color 0.2s;
}
.socialLink:hover > * {
  color: #23262f;
}
.socialLink:not(:last-child) {
  margin-right: 24px;
}
@media only screen and (max-width: 1339px) {
  .column {
    padding: 80px 64px 64px;
  }
  .logo {
    margin-right: 80px;
  }
}
@media only screen and (max-width: 1179px) {
  .column {
    flex: 0 0 34%;
  }
  .column:first-child {
    flex: 0 0 32%;
  }
}
@media only screen and (max-width: 1023px) {
  .body {
    padding: 0 40px;
  }
  .column {
    padding: 64px 32px 48px;
  }
  .logo {
    margin-right: 32px;
  }
  .tagline {
    padding: 0 40px;
  }
}
@media only screen and (max-width: 767px) {
  .body {
    padding: 0 32px;
    flex-flow: column nowrap;
  }
  .bodyWrapper {
    position: relative;
    padding-top: 32px;
  }
  .taglineWrapper {
    padding: 56px 0 48px;
    border: none;
  }
  .column:not(:first-child) {
    border-left: none;
  }
  .column {
    padding: 32px 0;
    border-bottom: 1px solid #e6e8ec;
    flex-flow: column nowrap;
  }
  .logo {
    width: 140px;
    margin: 0 0 32px;
  }
  .column:first-child .columnHeading {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    flex-flow: row nowrap;
    height: 24px;
  }
  .columnLinks {
    padding-top: 40px;
  }
  .tagline {
    padding: 0 32px;
    flex-flow: column;
  }
  .copyright {
    margin: 0 0 24px;
  }
}
