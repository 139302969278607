.noScrollBar::-webkit-scrollbar,
.noScrollBar::-webkit-scrollbar-track,
.noScrollBar::-webkit-scrollbar-thumb,
.noScrollBar::-webkit-scrollbar-thumb:hover {
  opacity: 0;
  display: none;
  width: 0;
  visibility: hidden;
  background: transparent;
  max-width: 0;
  height: 0;
  max-height: 0;
}
.section {
  margin-bottom: 136px;
}
.jumbotronWrapper {
  position: relative;
  padding-top: 160px;
  overflow: hidden;
}
.jumbotron {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px;
}
.jumbotronContent {
  position: relative;
  z-index: 3;
  max-width: 545px;
  margin-bottom: 143px;
}
.jumbotronTitle {
  font-family: "Jost", sans-serif;
  font-weight: 900;
  font-size: 64px;
  line-height: 1;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
  color: #24262e;
}
.jumbotronTag {
  margin-bottom: 32px;
  font-size: 26px;
  line-height: 1.4;
  font-weight: 700;
  color: #777e90;
  font-family: "Jost", sans-serif;
}
.jumbotronTag b {
  color: var(--theme);
}
.jumbotronCTA {
  margin-bottom: 80px;
  font-size: 16px;
  height: 48px;
}
.jumbotronScrollerBox {
  width: 52px;
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 42px;
  height: 42px;
  min-height: 42px;
  position: relative;
  overflow: hidden;
  transform: translateY(4px);
  animation: scrollBox 8s infinite 0.4s ease-out;
}
@keyframes scrollBox {
  from,
  to,
  20% {
    width: 52px;
  }
  25%,
  45% {
    width: 88px;
  }
  50%,
  70% {
    width: 60px;
  }
  75%,
  97% {
    width: 140px;
  }
}
@keyframes scroller {
  from,
  to,
  20% {
    transform: translateY(0px);
  }
  25%,
  45% {
    transform: translateY(calc(-1 * 42px * 1));
  }
  50%,
  70% {
    transform: translateY(calc(-1 * 42px * 2));
  }
  75%,
  97% {
    transform: translateY(calc(-1 * 42px * 3));
  }
}
.jumbotronScroller {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  animation: scroller 8s infinite 0.4s linear;
}
.jumbotronScroller > span {
  color: /*#5c9ee5;*/ var(--theme);
  font-weight: 800;
  font-size: inherit;
  width: auto;
  line-height: 1;
  min-height: 40px;
  margin-bottom: 2px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.jumbotronScrollIndicator {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: none;
  overflow: hidden;
  border: 2px solid #e6e8ec;
  transition: border-color 0.2s;
}
.jumbotronScrollIndicator:hover {
  border-color: #23262f;
}
.jumbotronScrollAnimation {
  display: flex;
  flex-direction: column;
  -webkit-transform: translateY(-23px);
  transform: translateY(-23px);
  -webkit-animation: jumbotronScrollArrowAnimation ease-out 1.4s infinite;
  animation: jumbotronScrollArrowAnimation ease-out 1.4s infinite;
}
.jumbotronScrollAnimationArrow {
  width: 24px;
  height: 24px;
  fill: #b1b5c3;
  transition: fill 0.2s;
}
.jumbotronScrollAnimationArrow:first-child {
  margin-bottom: 24px;
}
.jumbotronScrollIndicator:hover .jumbotronScrollAnimationArrow {
  fill: #23262f;
}
@keyframes jumbotronScrollArrowAnimation {
  80% {
    transform: translateY(23px);
  }
  100% {
    transform: translateY(23px);
  }
}
@-webkit-keyframes jumbotronScrollArrowAnimation {
  80% {
    transform: translateY(23px);
  }
  100% {
    transform: translateY(23px);
  }
}
.jumbotronBackground {
  position: absolute;
  top: -145px;
  right: calc(50% - 750px);
  width: 913px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.jumbotronBackgroundDots {
  position: absolute;
  z-index: 100;
  border-radius: 26px;
  height: fit-content;
  width: fit-content;
  background-color: white;
  padding: 4px 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
  opacity: 0;
  --bg: black;
}
.jumbotronBackgroundActive .jumbotronBackgroundDots {
  animation: dotsFadeIn 0.5s ease-out 8.5s, dotsHoldOn 1.5s linear 9s,
    dotsFadeOut 0.5s ease-out 10.5s;
}
@keyframes dotsFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes dotsFadeOut {
  to {
    opacity: 0;
  }
  from {
    opacity: 1;
  }
}
@keyframes dotsHoldOn {
  from,
  to {
    opacity: 1;
  }
}
.jumbotronBackgroundSuccess {
  position: absolute;
  z-index: 105;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: fit-content;
  height: fit-content;
  transform-origin: center center;
  opacity: 0;
}
.jumbotronBackgroundActive .jumbotronBackgroundSuccess {
  transform: scale(1);
  opacity: 1;
  animation: successHoldOff 11s linear,
    successFadeIn 0.3s cubic-bezier(0.83, 0, 0.17, 1) 11s;
}
@keyframes successHoldOff {
  from,
  to {
    transform: scale(0.7);
    opacity: 0;
  }
}
@keyframes successFadeIn {
  from {
    transform: scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.jumbotronBackgroundSuccessTitle {
  font-size: 28px;
  color: #23262f;
  font-weight: 800;
  font-family: "Jost", sans-serif;
  line-height: 1;
  margin-top: 7px;
}
.jumbotronBackgroundSuccessImage {
  height: 80px;
  width: 80px;
  background-image: url(../../assets/logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.jumbotronBackgroundImage {
  position: relative;
  width: 100%;
  opacity: 0;
  transform-origin: center center;
}
.jumbotronBackgroundDemo {
  display: flex;
  position: absolute;
  flex-flow: row nowrap;
  background-color: #fafafa;
  box-shadow: 0 0 0 1px #e0e0e0;
  width: 300px;
  height: 74px;
  align-items: center;
  border-radius: 4px;
}
.jumbotronBackgroundDemoCursor {
  position: absolute;
  background-image: url(../../assets/cursor.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 20px;
  width: 13px;
  /*transform: translate(40px, 60px);*/
  top: 11px;
  left: 12px;
  opacity: 0;
  transform-origin: center center;
  z-index: 10;
}
.jumbotronBackgroundActive .jumbotronBackgroundDemoCursor {
  animation: cursorHoldPosition 2s,
    cursorMove 0.6s cubic-bezier(0.65, 0, 0.35, 1) 2s,
    cursorHold 0.5s linear 2.6s, cursorClick 0.3s ease-out 3.1s,
    cursorHold 0.4s linear 3.4s, cursorFadeOut 0.6s ease-out 3.8s;
}
@keyframes cursorHold {
  from,
  to {
    opacity: 1;
  }
}
@keyframes cursorClick {
  from,
  to {
    opacity: 1;
    filter: brightness(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    filter: brightness(0.5);
    transform: scale(0.5);
  }
}
@keyframes cursorFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes cursorHoldPosition {
  from,
  to {
    opacity: 1;
    transform: translate(40px, 60px);
  }
}
@keyframes cursorMove {
  from {
    opacity: 1;
    transform: translate(40px, 60px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
.jumbotronBackgroundActive .jumbotronBackgroundDemo {
  opacity: 0.2;
  animation: backgroundHold 8.5s linear, backgroundFadeOut 0.5s ease-out 8.5s;
}
@keyframes backgroundHold {
  from,
  to {
    opacity: 1;
  }
}
@keyframes backgroundFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}
.jumbotronBackgroundDemoBox {
  height: 30px;
  width: 30px;
  margin: 0 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 1px rgba(145, 145, 145, 1);
  border-radius: 4px;
  background-color: rgb(250, 250, 250);
}
.jumbotronBackgroundActive .jumbotronBackgroundDemoBox {
  box-shadow: 0 0 0 1px rgba(145, 145, 145, 0);
  animation: boxHold 3.2s linear;
}
@keyframes boxHold {
  from,
  to {
    box-shadow: 0 0 0 1px rgba(145, 145, 145, 1);
  }
}
.jumbotronBackgroundDemoCaption {
  color: rgb(85, 85, 85);
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}
.jumbotronBackgroundDemoBoxPulse {
  opacity: 0;
}
.jumbotronBackgroundDemoCheck {
  opacity: 0;
  transform-origin: center center;
  z-index: 8;
  position: absolute;
  height: 30px;
  width: 30px;
  background-image: url(../../assets/check.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.jumbotronBackgroundActive .jumbotronBackgroundDemoBoxPulse {
  animation: pulseHoldOff 3.2s linear, pulseFadeIn 0.2s ease-in 3.2s,
    pulseHoldOn 4s linear 3.5s;
}
.jumbotronBackgroundActive .jumbotronBackgroundDemoCheck {
  transform: scale(1);
  opacity: 1;
  animation: checkHoldOff 7.5s linear, checkPop 0.4s linear 7.5s;
}
@keyframes checkHoldOff {
  from,
  to {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes checkPop {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.8);
  }
  80% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulseHoldOff {
  from,
  to {
    opacity: 0;
  }
}
@keyframes pulseFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes pulseHoldOn {
  from,
  to {
    opacity: 1;
  }
}
.jumbotronCards {
  display: flex;
  padding: 24px 8px;
  border-radius: 24px;
  background: #f4f5f6;
}
.jumbotronCard {
  flex: 0 0 calc(25% - 32px);
  width: calc(25% - 32px);
  margin: 0 16px;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  padding: 18px;
  border-radius: 16px;
  color: #23262f;
  transition: all 0.2s;
}
.jumbotronCard:hover {
  box-shadow: 0px 64px 64px -48px rgb(15 15 15 / 10%);
  background: #fcfcfd;
}
.jumbotronCardHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  height: 47px;
  margin-bottom: 8px;
  width: 100%;
}
.jumbotronCardIcon {
  display: flex;
  align-items: center;
  height: 47px;
  width: 47px;
  background-image: var(--static);
  background-size: 38px;
  background-repeat: no-repeat;
  background-position: center center;
}
.jumbotronCardHeaderTitle {
  font-family: "Jost", sans-serif;
  font-weight: 800;
  font-size: 23px;
  margin-left: 3px;
}
.jumbotronCard:hover .jumbotronCardIcon {
  background-image: var(--animated);
  background-size: cover;
}
.jumbotronCardDetails {
  height: 116px;
  font-weight: 500;
}
.process {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px;
  overflow: hidden;
}
.processHeader {
  max-width: 580px;
  margin: 0 auto 80px;
  text-align: center;
}
.processHeaderTitle {
  font-family: "Jost", sans-serif;
  font-weight: 800;
  font-size: 48px;
  line-height: 1.16667;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
}
.processHeaderTag {
  color: #353945;
}
.processList {
  display: flex;
  margin: 0 -16px;
}
.processListItem {
  position: relative;
  flex: 0 0 calc(25% - 32px);
  width: calc(25% - 32px);
  margin: 0 16px;
  text-align: center;
}
.processListItem::after {
  content: "";
  position: absolute;
  top: 47px;
  right: -84px;
  width: 130px;
  height: 2px;
  background-image: linear-gradient(
    90deg,
    #b1b5c3 0,
    #b1b5c3 6px,
    transparent 6px,
    transparent 14px
  );
  background-repeat: repeat-x;
  background-size: 12px auto;
}
.processListItemIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  margin: 0 auto 80px;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.03);
}
.processListItemIcon::before,
.processListItemIcon::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  border: 2px solid #b1b5c3;
}
.processListItemIcon::before {
  left: -24px;
}
.processListItemIcon::after {
  right: -24px;
}
.processListItem:first-child .processListItemIcon::before {
  display: none;
}
.processListItem:last-child .processListItemIcon::after {
  display: none;
}
.processListItem:last-child::after {
  display: none;
}
.processListItemIconImage {
  vertical-align: middle;
  width: 100%;
}
.processListItemNumber {
  margin-bottom: 32px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #777e90;
}
.processListItemTitle {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.processListItemDescription {
  color: #353945;
}
.blogWrapper {
  position: relative;
}
.blog {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px;
}
.blogInformation {
  display: flex;
  align-items: flex-start;
  margin-bottom: 80px;
}
.blogHeader {
  flex-grow: 1;
  overflow: hidden;
}
.blogHeaderTitle {
  font-family: "Jost", sans-serif;
  font-weight: 800;
  font-size: 48px;
  line-height: 1.16667;
  letter-spacing: -0.02em;
  margin-bottom: 40px;
}
.blogHeaderNavigation {
  display: flex;
}
.blogHeaderNavigationTab {
  display: flex;
  padding: 6px 12px;
  border-radius: 14px;
  background: none;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777e90;
  transition: all 0.2s;
}
.blogHeaderNavigationTab:not(:last-child) {
  margin-right: 24px;
}
.blogHeaderNavigationTab:disabled {
  background: #353945;
  color: #fcfcfd;
}
.blogHeaderNavigationTab:not(:disabled):hover {
  color: #353945;
}
.blogCTA {
  flex-shrink: 0;
  margin-left: 40px;
}
.blogCTAButton {
  width: 100%;
  height: 48px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
}
.blogPosts {
  margin: -32px -18px 0;
}
.blogPosts::after {
  content: " ";
  display: table;
  clear: both;
}
:global .aQYG7gEJarjEJMaU .slick-list {
  overflow: hidden;
}
:global .aQYG7gEJarjEJMaU .slick-slide {
  opacity: 0;
  transform-origin: center center;
  transform: scale(0.8);
  transition: opacity 0.4s, transform 0.4s;
  padding: 0 16px;
}
:global .aQYG7gEJarjEJMaU .slick-slide:not(:not(.slick-active)) {
  opacity: 1;
  transform: scale(1);
  transform-origin: center center;
}
:global .slick-arrow {
  position: absolute;
  z-index: 2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 0;
  transition: all 0.2s;
}
:global .slick-arrow:hover {
  box-shadow: inset 0 0 0 2px #e6e8ec;
}
:global .aQYG7gEJarjEJMaU .slick-arrow {
  bottom: 0;
}
:global .aQYG7gEJarjEJMaU .slick-prev {
  left: 16px;
}
:global .aQYG7gEJarjEJMaU .slick-next {
  left: 64px;
}
.blogPost {
  display: block;
  width: calc(50% - 36px);
  margin: 32px 18px 0;
  float: right;
  color: #23262f;
  cursor: pointer;
}
.blogPost:first-child {
  float: left;
}
.blogPost:not(:first-child) {
  display: flex;
  flex-direction: row-reverse;
  padding-left: 60px;
}
.blogPostPreview {
  position: relative;
  overflow: hidden;
}
.blogPostPreviewImage {
  width: 100%;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}
.blogPost:first-child .blogPostPreview {
  margin-bottom: 40px;
  border-radius: 20px;
}
.blogPost:not(:first-child) .blogPostPreview {
  flex: 0 0 45.65%;
  border-radius: 16px;
}
.blogPostLine {
  display: flex;
}
.blogPostWrap {
  flex-grow: 1;
}
.blogPostSubtitle {
  transition: color 0.2s;
}
.blogPost:first-child .blogPostSubtitle {
  margin-bottom: 16px;
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: -0.01em;
}
.blogPostContent {
  color: #777e90;
}
.blogPost:first-child .blogPostContent {
  font-size: 16px;
  line-height: 1.5;
}
.blogPostDetails {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 16px;
}
.blogPost:not(:first-child) .blogPostSubtitle {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.blogPost:not(:first-child) .blogPostContent {
  margin-bottom: auto;
}
.blogPostDate {
  margin-top: 24px;
  color: #777e90;
}
.blogPost:hover .blogPostPreviewImage {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.blogPost:hover .blogPostSubtitle {
  /*color: #3772ff;*/
  color: var(--theme);
}
@media only screen and (max-width: 1259px) {
  .processListItem::after {
    right: -66px;
    width: 100px;
  }
}
@media only screen and (max-width: 1179px) {
  .section {
    margin-bottom: 80px;
  }
  .jumbotronWrapper {
    padding-top: 80px;
  }
  .jumbotronContent {
    max-width: 420px;
    margin-bottom: 80px;
  }
  .jumbotronCTA {
    margin-bottom: 0;
    box-shadow: none !important;
  }
  .jumbotronScrollIndicator {
    display: none;
  }
  .jumbotronBackground {
    top: -80px;
    right: calc(50% - 550px);
    width: 600px;
  }
  .jumbotronCard {
    padding: 16px 16px;
  }
  .jumbotronCardDetails {
    height: 163px;
    margin-bottom: 0;
  }
  .processHeader {
    margin-bottom: 64px;
  }
  .processListItem::after {
    right: -50px;
    width: 60px;
  }
  .blogInformation {
    margin-bottom: 48px;
  }
  .blogPosts {
    margin: 0 -16px;
  }
  .blogPostsSlider {
    padding-bottom: 72px;
    visibility: hidden;
  }
  :global .aQYG7gEJarjEJMaU .slick-initialized {
    visibility: visible;
  }
  .blogPost {
    float: left;
    width: 100%;
    margin: 0;
  }
  .blogPost:not(:first-child) {
    display: block;
    padding: 0;
  }
  .blogPostPreview {
    margin-bottom: 24px;
    border-radius: 16px;
  }
  .blogPost:first-child .blogPostPreview {
    margin-bottom: 24px;
    border-radius: 16px;
  }
  .blogPostPreview::before {
    content: "";
    display: block;
    padding-bottom: 77%;
  }
  .blogPostPreviewImage {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .blog .blogPost .blogPostSubtitle {
    font-size: 24px;
    line-height: 32px;
  }
  .blog .blogPost .blogPostContent {
    margin-bottom: auto;
    font-size: inherit;
    line-height: inherit;
  }
  .blog .blogPost .blogPostDate,
  .blog .blogPost .blogPostCTA {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .jumbotron {
    padding: 0 40px;
  }
  .jumbotronTag {
    max-width: 300px;
  }
  .jumbotronBackground {
    right: calc(50% - 500px);
  }
  .jumbotronCards {
    margin: 0 -20px;
  }
  .jumbotronCard {
    padding: 16px 0;
  }
  .jumbotronCard:hover {
    box-shadow: none;
    background: none;
  }
  .jumbotronCardDetails {
    height: 146px;
    margin-bottom: 12px;
  }
  .process {
    padding: 0 40px;
  }
  .processList::before,
  .processList::after {
    content: "";
    flex-shrink: 0;
    width: 40px;
    height: 1px;
  }
  .processList {
    margin: 0 -40px;
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: transparent;
  }
  .processListItem {
    flex: 0 0 256px;
    width: 256px;
    margin: 0;
  }
  .processListItem:not(:last-child) {
    margin-right: 32px;
  }
  .processListItem::after {
    right: -79px;
    width: 120px;
  }
  .blog {
    padding: 0 40px;
  }
}
@media only screen and (max-width: 767px) {
  .section {
    margin-bottom: 64px;
  }
  .jumbotronWrapper {
    padding-top: 32px;
  }
  .jumbotron {
    padding: 0 32px;
  }
  .jumbotronContent {
    margin-bottom: 16px;
  }
  .jumbotronTitle {
    font-size: 48px;
    line-height: 1.16667;
  }
  .jumbotronBackground {
    position: static;
    width: auto;
  }
  .jumbotronCards {
    display: block;
    margin: -32px -16px 0;
    padding: 24px;
  }
  .jumbotronCard {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 16px 24px;
  }
  .jumbotronCardIcon {
    /*flex-shrink: 0;
    width: 40px;
    margin: 0 16px 0 0;*/
  }
  .jumbotronCardDetails {
    /* flex-grow: 1;*/
    width: 100%;
    height: fit-content;
  }
  .process {
    padding: 0 32px;
  }
  .processHeader {
    margin-bottom: 40px;
  }
  .processHeaderTitle {
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: -0.01em;
  }
  .processListItem {
    text-align: left;
  }
  .processListItem::after {
    right: 0;
  }
  .processListItemIcon {
    margin: 0 0 32px 0;
  }
  .blogWrapper {
    padding-bottom: 80px;
  }
  :global .aQYG7gEJarjEJMaU .slick-next {
    left: auto;
    right: calc(50% - 48px);
  }
  :global .aQYG7gEJarjEJMaU .slick-prev {
    left: calc(50% - 48px);
  }
  .blog {
    padding: 0 32px;
  }
  .blogInformation {
    display: block;
  }
  .blogHeaderTitle {
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: -0.01em;
    margin-bottom: 24px;
    text-align: center;
  }
  .blogHeaderNavigation {
    margin: 0 -32px;
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  .blogHeaderNavigation::before,
  .blogHeaderNavigation::after {
    content: "";
    flex-shrink: 0;
    width: 32px;
    height: 1px;
  }
  .blogHeaderNavigationTab {
    flex-shrink: 0;
  }
  .blogHeaderNavigationTab:not(:last-child) {
    margin-right: 20px;
  }
  .blogCTA {
    position: absolute;
    left: 32px;
    right: 32px;
    bottom: 0;
    width: calc(100% - 64px);
    margin: 0;
  }
}
