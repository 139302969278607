@import-normalize;
:root {
  --white: #fcfcfd;
  --theme: #4671f6;
  /*--theme: #60AFEA;*/
}
body,
html {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  background: var(--white, white);
}
#root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: auto;
  max-width: 100vw;
  overflow: auto;
  display: flex;
  flex-flow: column nowrap;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1.71429;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #23262f;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
div,
input,
textarea,
select,
button,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
a:focus {
  outline: none;
}
img,
fieldset,
a img {
  border: none;
}
svg,
img {
  vertical-align: middle;
}
a,
a:focus,
a:visited,
a:hover {
  text-decoration: none;
  cursor: pointer;
}
button {
  cursor: pointer;
  border: inherit;
  background: inherit;
  color: inherit;
}
button:disabled {
  cursor: not-allowed;
}
