.root {
  position: relative;
  z-index: 10;
  /*padding: 20px 0;*/
  min-height: 80px;
  box-shadow: inset 0 -1px 0 #e6e8ec;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px;
}
.logo {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-right: 32px;
  cursor: pointer;
}
.navLink {
  line-height: 40px;
  color: #777e90;
  transition: all 0.2s;
}
.navLink:hover,
.navLink:active {
  color: #23262f;
}
.navLink:not(:last-child) {
  margin-right: 40px;
}
@media only screen and (max-width: 1179px) {
  .wrapper {
    padding: 0 40px;
  }
  .logo {
    margin-right: 28px;
  }
  .navLink:not(:last-child) {
    margin-right: 30px;
  }
}
@media only screen and (max-width: 1023px) {
  .wrapper {
    padding: 0 20px;
  }
  .logo {
    margin-right: 16px;
  }
  .navLink:not(:last-child) {
    margin-right: 20px;
  }
}

.logoImage {
  width: 48px;
}
.logoCaption {
  font-size: 30px;
  font-weight: 900;
  line-height: 1;
  color: #23262f;
  margin-left: 10px;
  font-family: "Jost", sans-serif;
}
.nav {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0;
  flex-grow: 1;
  font-family: "Jost", sans-serif;
  font-size: 15px;
  font-weight: 700;
}
.navLinks {
  display: flex;
  flex-flow: row nowrap;
  margin-right: auto;
}
.cta {
  display: flex;
  flex-flow: row nowrap;
}
